<template>
  <b-container fluid class="my-5 px-5">
    <h1 class="h2 mb-3">{{ title }}</h1>
    <p>{{ description }}</p>
    <slot name="calculator"></slot>
  </b-container>
</template>

<script>
export default {
  name: 'CalculatorDetail',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
}
</script>
